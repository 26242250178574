/**
* Created by hansxing on 2018/6/6.
*/
<style lang="scss" scoped>

    .color-1989FA {
        color: #1989FA;
    }

    .excel-file-btns {
        text-align: right;
        a {
            position: relative;
            display: inline-block;
        }
        .up-form {
            display: inline-block;
        }
        input {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            outline: none;
            opacity: 0;
        }
        input[type="file"]{
            font-size:0
        }
        .up-form:hover, .upload:hover, a:hover {
            cursor: pointer;
        }

    }

    .list-title {
        font-size: 14px;
        color: #333;
        padding: 10px;
        border-bottom: 1px solid #eaeaea;
    }
</style>

<style lang="scss">
    /*.order_import_tips {*/
    /*width: 70%;*/
    /*}*/

    .order_import_tips.error {
        width: 40%;
    }

    .order_import_tips .el-message-box__message {
        max-height: calc(100vh - 300px);
        overflow: scroll;
    }

    .order_import_tips p {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;
    }
</style>
<template>
    <el-main class="com-eTrip-section">
        <div class="eTrip-section-cont" v-loading="loading">
            <form ref="file-selector-f" class="up-form">
                <el-table
                        :data="tableData"
                        :show-header="false"
                        style="width: 100%">
                    <el-table-column
                            fixed
                            label="标题">
                        <template slot-scope="scope">
                            <div>
                                <b>{{scope.row}}</b> 订单导入
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                            fixed="right"
                            label="操作"
                            width="300px">
                        <template slot-scope="scope">
                            <div class="excel-file-btns">
                                <a class="color-1989FA" href="javascript:void(0);">上传Excel文件
                                    <input class="upload" type="file" @change="handleSelectFile(scope.$index)"
                                           accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"/>
                                </a>
                                <a class="color-1989FA" href="javascript:void(0);"
                                   @click="getData(1,scope.$index+2)">查看记录</a>
                            </div>

                        </template>
                    </el-table-column>
                </el-table>
            </form>
            <div style="background-color: #fff;margin-top: 20px;" v-if="orderIndex">
                <h2 class="list-title">查看{{tableData[orderIndex - 2]}}订单导入记录</h2>
                <el-table
                        :row-style="{color:'#909399','font-wight':'100'}"
                        :data="importRecordData">
                    <el-table-column
                            fixed
                            label="导入时间">
                        <template slot-scope="scope">
                            {{scope.row.createTime | dateCus('yyyy-MM-dd hh:mm:ss')}}
                        </template>
                    </el-table-column>
                    <el-table-column
                            fixed
                            label="操作人">
                        <template slot-scope="scope">
                            {{scope.row.userName + ' ' + scope.row.userMobile}}
                        </template>
                    </el-table-column>
                    <el-table-column
                            fixed="right"
                            label="上传文件"
                            width="100px">
                        <template slot-scope="scope">
                            <a class="color-1989FA" :href="scope.row.excelUrl">下载</a>
                        </template>
                    </el-table-column>
                </el-table>
                <div style="text-align: right;padding: 10px 0" v-if="importRecordData && importRecordData.length > 0">
                    <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleNextPage"
                            :current-page.sync="form.pageIndex"
                            :page-size="form.pageSize"
                            layout="total, prev, pager, next"
                            :total="total">
                    </el-pagination>
                </div>
            </div>

        </div>
    </el-main>

</template>

<script type="text/ecmascript-6">
    import {up2QiNiu} from '@/common/js/up2QinNiu/index'

    export default {
        //定义模版数据
        data() {
            return {
                tableData: ["携程", "飞猪", "马蜂窝", "去哪儿", "线下"],
                //导单类型 1携程 2飞猪 3马蜂窝 4去哪儿 5线下
                url: null,
                loading: false,
                importRecordData: [],
                orderIndex: null,
                total: 0,
                form: {
                    pageSize: 10,
                    pageIndex: 1,
                    excelType: null
                },
                errorsMessage: {
                    "9": "解析出错",
                    "10": "文件类型不对",
                    "11": "文件不是对应的渠道",
                    "12": "以下第三方订单缺少对应的资源ID，请确认后再导入。",
                    "-1": '抱歉，导入订单失败，请重试！'
                }

            }
        },
        components: {},
        //计算属性
        computed: {},
        //主件被加载完成
        mounted: function () {
        },
        //定义事件方法
        methods: {
            async handleSelectFile(index) {
                this.loading = true;
                const file = event.currentTarget.files[0];
                this.$refs['file-selector-f'].reset();
                const date = new Date();
                const name = file.name.split('.');
                const fileName = `${name[1]}-${date.getTime()}.${name[name.length - 1]}`;
                try {
                    this.url = await up2QiNiu(this, '/otaorder/' + (date.toLocaleDateString()) + '/' + fileName, file);
                    if (this.url)
                        this.postData(index);
                    else {
                        this.loading = false;
                        this.$message.error("抱歉文件上传失败，请重新上传!")
                    }
                } catch (e) {
                    this.loading = false;
                    this.$message.error("抱歉文件上传失败，请重新上传!")
                }

            },
            async postData(index) {
                this.loading = true;
                const ret = await  this.http('/galaxyOrder/otaOrderExcel', {
                    excelUrl: this.url,
                    excelType: index + 2
                }, 'POST', false);
                if (ret.success && ret.data && ret.data.isSuccess === 1) {
                    this.$message.success("导入订单成功！");
                    this.getData(1, index + 2);
                } else if (ret.data && ret.data.isSuccess === 0) {
                    //没有资源
                    if (ret.data.noInventoryThirdOrderIds)
                        return this.handleError(ret.data.noInventoryThirdOrderIds);
                    else if (ret.data.thirdOrderInfoDtos)
                        this.handleCover(ret.data.thirdOrderInfoDtos, index)
                    else if (ret.data && ret.data.errorCode)
                        this.handleError(null, ret.data.errorCode);
                    else
                        this.$message.error(this.errorsMessage["-1"])
                }
                else
                    this.$message.error(this.errorsMessage["-1"])
                this.loading = false;

            },
            async getData(pageIndex, excelType) {
                this.orderIndex = excelType || this.orderIndex;
                this.loading = true;
                if (pageIndex)
                    this.form.pageIndex = pageIndex;
                if (excelType)
                    this.form.excelType = excelType;
                const ret = await this.http('/galaxyOrder/viewOTAOrderRecord', this.form, 'POST', false);
                this.importRecordData = [];
                if (ret.success && ret.data) {
                    this.importRecordData = ret.data.data;
                    this.total = ret.data.total;
                }
                else if (ret && ret.errors)
                    this.$message.error(ret.errors[0].message)
                else
                    this.$message.error('抱歉，获取数据失败!')

                this.loading = false;

            },
            handleNextPage(val) {
                this.getData(val);
            },
            handleSizeChange(val) {
                this.getData(val);
            },
            handleError(orderIds, errorCode) {
                this.loading = false;
                let tpl = this.errorsMessage[errorCode];
                if (!errorCode) {
                    tpl = '<p>以下第三方订单对应的资源ID不存在/无库存/未上架，请确认后再导入。</p>';
                    orderIds.forEach((data, i) => {
                        tpl += `<p>${i + 1}、第三方订单ID:${data}</p>`;
                    });
                }
                this.$alert(`<div>${tpl}</div>`, '导入失败', {
                    confirmButtonText: '确认',
                    dangerouslyUseHTMLString: true,
                    customClass: "order_import_tips error"
                });
            },
            async handleCover(list, index) {
                let str_tpl = '<p>以下第三方订单系统中已存在，是否确认做数据覆盖导入？</p>';
                if (list) {
                    list.forEach((data, i) => {
                        str_tpl += `<p clsss="order_import_tips_p">${i + 1}、订单号:${data.thirdOrderId}</p>`;
                    })
                }

                const tpl = `<div>${str_tpl}</div>`;

                const ret = await  this.$confirm(tpl, '导入提醒', {
                    confirmButtonText: '确认',
                    cancelButtonText: '取消',
                    dangerouslyUseHTMLString: true,
                    customClass: "order_import_tips "
                });
                if (ret === 'confirm') {
                    this.loading = true;
                    const ret = await this.http('/galaxyOrder/coverOTAOrderExcel', {
                        excelUrl: this.url,
                        excelType: index + 2
                    }, "POST", false);
                    if (ret.success && ret.data && ret.data.isSuccess === 1) {
                        this.$message.success("导入订单成功！");
                        this.getData(1, index + 2);
                    }
                    else if (ret.data && ret.data.noInventoryThirdOrderIds)
                        this.handleError(ret.data.noInventoryThirdOrderIds);
                    else if (ret.data && ret.data.errorCode)
                        this.handleError(null, ret.data.errorCode);
                    else
                        this.$message.error(this.errorsMessage["-1"])
                    this.loading = false;
                }
            }
        },
        //监听模版变量
        watch: {}

    }
</script>

